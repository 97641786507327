
import React, { useState, useEffect } from 'react';

function ImageSlider({ images, interval = 2500 }) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  //   const prevImage = () => {
  //     setCurrentImageIndex((prevIndex) =>
  //       prevIndex === 0 ? images.length - 1 : prevIndex - 1
  //     );
  //   };

  const handleDotClick = (index) => {
    setCurrentImageIndex(index);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      nextImage();
    }, interval);

    return () => clearInterval(intervalId);
  }, [currentImageIndex, interval]);

  return (
    <div className='d-flex flex-column align-items-center'>
      <img
        className='img-fluid w-100' 
        src={images[currentImageIndex].image}
        alt='stock market tips'
      />
      <div>
        {images.map((image, index) => (
          <span
            aria-hidden
            // key={index}
            onClick={() => handleDotClick(index)}
            style={{
              display: 'inline-block',
              width: '10px',
              height: '10px',
              margin: '0 5px',
              borderRadius: '50%',
              backgroundColor:
                index === currentImageIndex ? '#3C86DD' : '#DFF0FD',
              cursor: 'pointer',
            }}
          />
        ))}
      </div>
    </div>
  );
}

export default ImageSlider;
