import React from "react";
import { color } from "./colours";
function Footer() {
  // const openURL = (url) => {

  //   // Open the URL in a new tab or window
  //   window.open(url, '_blank');
  // };
  const handleCallClick = () => {
    const phoneNumber = 'tel:+918888864635'; // Replace with the actual phone number
    window.location.href = phoneNumber;
  };
  return (
    <div style={{ backgroundColor:color.cream }}>
      <div className="container">
        <div className="d-flex justify-content-between mt-3 pt-5 flex-wrap">
          <div
            className="d-flex col-12 col-lg-4"
            onClick={() =>
              window.open(
                "https://www.google.com/maps/dir//Near+Shivaji+Statue,+Pundalik+Nagar,+Husain+Colony,+Nyay+Nagar,+Aurangabad,+Maharashtra+431009/@19.8658571,75.2749463,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bdba2896ae0c93f:0x6210a72bd6fe8fad!2m2!1d75.3573398!2d19.8658752?entry=ttu",
                "_blank"
              )
            }
          >
            <i class="bi bi-geo-alt-fill me-2"></i>
            <spam>
              Near Shivaji Statue, Pundalik Nagar, Husain Colony, Nyay Nagar,
              Aurangabad, Maharashtra 431009
            </spam>
          </div>
          <div className="col-12 col-lg-4 ps-lg-5 text-lg-center">
            <i class="bi bi-envelope-fill me-2"></i>
            <spam>info@gayatrijewellers.com</spam>
          </div>
          <div onClick={()=>handleCallClick()} className="col-12 col-lg-4 ps-lg-5 text-lg-center">
            <i class="bi bi-telephone-fill me-2"></i>
            <spam>+918888864635</spam>
          </div>
        </div>

        <div className="d-flex pt-4 flex-column align-items-center">
          <h4>Follow Us</h4>
          <div>
            <i class="bi bi-instagram"></i>
            <i class="bi bi-facebook mx-3"></i>
            <i class="bi bi-youtube"></i>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center p-4 pb-0 text-center">
        <p>© Copyright 2023. All Rights Reserved Gayatri Jewellers.</p>
      </div>
      <div className="d-flex justify-content-center p-4 pt-0 text-center">
        <p>Designs and developed by DSK Solutions.</p>
      </div>
    </div>
  );
}

export default Footer;
