import React, { useEffect, useState } from "react";
import { getProductList } from "../api/api";
import TopNavbar from "../utils/topNavbar";
import Footer from "../utils/footer";

function Necklaces() {
  const [necklacesList, setNecklacesList] = useState([]);
  const getNecklaces = () => {
    getProductList("Necklace")
      .then((res) => {
        setNecklacesList(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(()=>{
    getNecklaces();
  },[])
  return (
    <div>
      <TopNavbar
      activeScreen={'Necklaces'} />
      <div className="d-flex flex-wrap container" style={{ minHeight: "60vh" }}>
        {necklacesList.map((item) => (
          <div className="col-lg-2 col-6 p-2">
            <div className="shadow rounded d-flex flex-column align-items-center p-3">
              <img
                src={process.env.REACT_APP_COMPRESSED_IMAGES_BASE_PATH+item.productPath}
                alt="img"
                className="img-fluid rounded w-75"
              />
              <div className="p-2">
                <h4>{item.price}</h4>
                <h6>{item.name}</h6>
                <p>{item.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
}
export default Necklaces;
