import React, { useEffect, useState } from "react";
import TopNavbar from "../utils/topNavbar";
import Footer from "../utils/footer";
import ImageSlider from "../utils/imageSlider";
import { useNavigate } from "react-router-dom";
import { getTrendingProductList } from "../api/api";
import { color } from "../utils/colours";

function Home() {
  const navigator = useNavigate();
  const [trendingProductList, setTrendingProductList] = useState([]);
  const getTrending = () => {
    getTrendingProductList()
      .then((res) => {
        console.log(res.data);
        setTrendingProductList(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getTrending();
  }, []);

  const BannerImage = [
    {
      id: 1,
      image: "./images/banner-1.png",
    },
    {
      id: 2,
      image: "./images/banner-2.png",
    },
    {
      id: 3,
      image: "./images/banner-3.png",
    },
  ];
  return (
    <div style={{ backgroundColor: color.dark }}>
      <TopNavbar activeScreen={"Home"} />

      <div>
        <ImageSlider images={BannerImage} />
      </div>

      <div className="position-relative mt-4">
        <div className="ps-5 position-absolute" style={{ width: "40%" }}>
          <h2 style={{ color: color.golden, fontSize: 50, fontFamily:"Old sandard TT" }}>
            SHOP BY <br />
            CATEGORY
          </h2>
        </div>
        <div className="d-flex flex-row justify-content-around align-items-center">
          <img
            style={{ width: "35%", marginTop: 130 }}
            src={"./banglesMenu.png"}
            alt="img"
            className="img-fluid "
          />
          <img
            style={{ width: "25%", marginBottom: 100 }}
            src={"./magalsutraMenu.png"}
            alt="img"
            className="img-fluid "
          />
          <img
            style={{ width: "25%", marginTop: 100 }}
            src={"./ringMenu.png"}
            alt="img"
            className="img-fluid "
          />
        </div>
      </div>

      <div
        className="mt-4 py-4 text-center"
        style={{ backgroundColor: color.cream }}
      >
        <h2 style={{ color: color.golden }}>Gayatri Jewellers</h2>
        <div className="d-flex flex-wrap justify-content-around text-white pt-2">
          <div className="col-lg-1 col-6 px-2 text-center">
            <img src={"./exchangeLogo.png"} alt="img" className="img-fluid " />
            <p>Life time exchange</p>
          </div>
          <div className="col-lg-1 col-6 px-2 text-center">
            <img src={"./trustLogo.png"} alt="img" className="img-fluid " />
            <p>Purity gurrenty</p>
          </div>
          <div className="col-lg-1 col-6 px-2 text-center">
            <img src={"./bsiLogo.png"} alt="img" className="img-fluid " />
            <p>BSI hallmark</p>
          </div>
          {/* <div className="col-lg-2 col-6 px-2 text-center">
            <img
              src={"./images/Necklace.jpg"}
              alt="img"
              className="img-fluid "
            />
            <p>Old exchange</p>
          </div>
          <div className="col-lg-2 col-6 px-2 text-center">
            <img
              src={"./images/Necklace.jpg"}
              alt="img"
              className="img-fluid "
            />
            <p>14 days exchange</p>
          </div> */}
        </div>
      </div>

      <div className="position-relative mt-4">
        <div className="ps-5 position-absolute" style={{ width: "40%" }}>
          <h2 style={{ color: color.golden, fontSize: 60, fontFamily:"Old sandard TT"  }}>BESTSELLER</h2>
        </div>
        <div className="d-flex flex-row justify-content-around align-items-center">
          <img
            style={{ width: "35%", marginTop: 100 }}
            src={"./bestseller1.png"}
            alt="img"
            className="img-fluid "
          />
          <img
            style={{ width: "25%", marginBottom: 100 }}
            src={"./bestseller2.png"}
            alt="img"
            className="img-fluid "
          />
          <img
            style={{ width: "25%", marginTop: 100 }}
            src={"./bestseller3.png"}
            alt="img"
            className="img-fluid "
          />
        </div>
      </div>

      <div className="position-relative mt-4">
        <div className="ps-5 position-absolute" style={{ width: "40%" }}>
          <h2 style={{ color: color.golden, fontSize: 60, fontFamily:"Old sandard TT"  }}>NEW INS</h2>
        </div>
        <div className="d-flex flex-row justify-content-around align-items-center">
          <img
            style={{ width: "35%", marginTop: 100 }}
            src={"./bestins1.png"}
            alt="img"
            className="img-fluid "
          />
          <img
            style={{ width: "25%", marginBottom: 100 }}
            src={"./bestins2.png"}
            alt="img"
            className="img-fluid "
          />
          <img
            style={{ width: "25%", marginTop: 100 }}
            src={"./bestins3.png"}
            alt="img"
            className="img-fluid "
          />
        </div>
      </div>
      {/* <div className=" m-4 p-2">
        <div className="mt-5 d-flex flex-column align-items-center">
          <h3 style={{ fontFamily: "Pattaya, sans-serif" }}>Necklaces</h3>
          <div style={{ width: 60, backgroundColor: "#f3d56d", height: 2 }} />
        </div>
        <div className="d-lg-flex container align-items-center justify-content-between my-5">
          <div className="col-lg-5 col-12 mt-3 order-lg-2 d-flex justify-content-center">
            <img
              src={"./images/Necklace.jpg"}
              alt="img"
              className="img-fluid rounded w-50"
            />
          </div>
          <div className="col-lg-5 col-12 order-lg-1 d-flex flex-column">
            <p className="mt-4">
              Gold necklaces come in a wide array of designs, catering to
              various tastes and occasions. Designs can range from simple and
              classic to intricate and ornate. Some necklaces may feature unique
              pendant designs, while others may be standalone chains with
              varying link styles.{" "}
            </p>
            <p className="mt-4">
              Gold necklaces are crafted from gold, a precious metal valued for
              its beauty and rarity. The purity of the gold is often indicated
              in karats, with common options being 14k, 18k, or 24k. Other
              metals may be alloyed with gold to enhance durability and modify
              color.{" "}
            </p>
            <button
              className="btn rounded-0 fw-bold px-5 mt-2 align-self-center"
              onClick={() => navigator("/necklaces")}
              style={{ backgroundColor: "#f3d56d", color: "#fff" }}
            >
              EXPLORE
            </button>
          </div>
        </div>
      </div>

      <div className="shadow rounded m-lg-4 m-2 p-2">
        <div className="mt-5 d-flex flex-column align-items-center">
          <h3 style={{ fontFamily: "Pattaya, sans-serif" }}>Bangels</h3>
          <div style={{ width: 60, backgroundColor: "#f3d56d", height: 2 }} />
        </div>
        <div className="d-lg-flex container align-items-center justify-content-between my-5">
          <div className="col-lg-5 col-12 mt-3  d-flex justify-content-center">
            <img
              src={"./images/Bangles.jpg"}
              alt="img"
              className="img-fluid rounded w-50"
            />
          </div>
          <div className="col-lg-5 col-12 d-flex flex-column">
            <p className="mt-4">
              Gold bangles come in various designs, ranging from simple and
              sleek to intricately detailed. Common designs include solid
              bangles, textured or patterned bangles, and those adorned with
              gemstones, diamonds, or other embellishments.
            </p>
            <p className="mt-4">
              Gold bangles are crafted from gold, a precious metal known for its
              beauty, rarity, and durability. The purity of the gold is often
              indicated in karats (e.g., 14k or 18k).
            </p>
            <button
              className="btn rounded-0 fw-bold px-5 mt-2 align-self-center"
              onClick={() => navigator("/bangels")}
              style={{ backgroundColor: "#f3d56d", color: "#fff" }}
            >
              EXPLORE
            </button>
          </div>
        </div>
      </div>

      <div className=" m-4 p-2">
        <div className="mt-5 d-flex flex-column align-items-center">
          <h3 style={{ fontFamily: "Pattaya, sans-serif" }}>Rings</h3>
          <div style={{ width: 60, backgroundColor: "#f3d56d", height: 2 }} />
        </div>

        <div className="d-lg-flex container align-items-center justify-content-between my-5">
          <div className="col-lg-5 col-12 mt-3 order-lg-2  d-flex justify-content-center">
            <img
              src={"./images/Ring.jpg"}
              alt="img"
              className="img-fluid rounded w-50"
            />
          </div>
          <div className="col-lg-5 col-12 order-lg-1 d-flex flex-column">
            <p className="mt-4">
              Gold rings come in a wide variety of designs, catering to diverse
              tastes and occasions. Common designs include: Solitaire Rings,
              Three-Stone Rings, Eternity Rings, Plain Bands.
            </p>
            <p className="mt-4">
              Types of Gold Rings: Wedding Bands, Engagement Rings, Fashion
              Rings
            </p>
            <p className="mt-4">
              Gold rings are typically made from gold, a precious metal known
              for its durability, beauty, and malleability. The purity of the
              gold is often indicated in karats, such as 14k, 18k, or 24k.
              Different alloys may be added to gold to enhance its strength and
              alter its color.
            </p>
            <button
              className="btn rounded-0 fw-bold px-5 mt-2 align-self-center"
              onClick={() => navigator("/rings")}
              style={{ backgroundColor: "#f3d56d", color: "#fff" }}
            >
              EXPLORE
            </button>
          </div>
        </div>
      </div> */}

      {/* <div className="container my-4">
        <div className="mt-5 d-flex flex-column align-items-center">
          <h3 style={{ fontFamily: "Pattaya, sans-serif" }}>Trending Now</h3>
          <div style={{ width: 60, backgroundColor: "#f3d56d", height: 2 }} />
        </div>

        <div
          className="d-flex flex-wrap container"
          style={{ minHeight: "60vh" }}
        >
          {trendingProductList.map((item) => (
            <div className="col-lg-2 col-6 p-2">
              <div className="shadow rounded d-flex flex-column align-items-center p-3">
                <img
                  src={
                    process.env.REACT_APP_IMAGES_BASE_PATH + item.productPath
                  }
                  alt="img"
                  className="img-fluid rounded w-75"
                />
                <div className="p-2">
                  <h6>{item.category}</h6>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div> */}
      <div className="mt-5">
        <video className="w-100" autoPlay loop muted>
          <source src="./ringVideo.mp4" type="video/mp4" allowFullScreen />
        </video>
      </div>

      <div style={{ backgroundColor: color.cream }}>
        <div className="m-lg-4 m-2 p-2">
          <div className="mt-5 ms-5 d-flex flex-column align-items-start">
            <h1 style={{ color: color.golden, fontSize:60, fontFamily:"Old standard TT" }}>OUR STORY</h1>
          </div>
          <div className="d-lg-flex container align-items-center justify-content-between my-5">
            <div className="col-lg-6 col-12 mt-3  d-flex justify-content-center">
              <img
                src={"./bestseller1.png"}
                alt="img"
                className="img-fluid w-75"
              />
            </div>
            <div className="col-lg-6 col-12 d-flex flex-column">
              <p
                className="d-flex align-items-center justify-content-center"
                style={{ fontSize: 30, fontFamily:"Yatra one" }}
              >
                Welcome toGayatri Jewellers With 24 years of unwavering trust,
                we take pride in crafting mesmerizing designs for every
                cherished occasion. From timeless classics to contemporary
                marvels, our collection embodies sophistication and grace.
                Experience the artistry of less making, where each piece
                reflects our commitment to excellence. Discover the epitome of
                luxury and craftsmanship at our Gold Shop – where your journey
                with fine jewelry begins."
              </p>
            </div>
          </div>
        </div>

        <div className="m-lg-4 m-2 p-2">
          <div className="d-lg-flex container align-items-center justify-content-between my-5">
            <div className="col-lg-6 col-12 d-flex flex-column align-items-center">
              <h1 style={{ fontFamily:"Yatra one" }}>
                SHOP LOCATION
              </h1>
              <p
                className="d-flex align-items-center justify-content-center"
                style={{ fontSize: 30, fontFamily:"Yatra one" }}
              >
                Pundlik Nagar Rd, near Shivaji Statue, Husain Colony, Nyay
                Nagar, Aurangabad, Maharashtra 431009
              </p>
            </div>
            <div className="col-lg-6 col-12  d-flex justify-content-center">
              <video className="w-100" autoPlay loop muted>
                <source
                  src="./shopVideo.mp4"
                  type="video/mp4"
                  allowFullScreen
                />
              </video>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default Home;
