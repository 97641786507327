import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './pages/home';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Bangels from './pages/bangels';
import Necklaces from './pages/necklaces';
import Rings from './pages/rings';

function App() {
  return (
    <Router>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/bangels" element={<Bangels />} />
      <Route path="/necklaces" element={<Necklaces />} />
      <Route path="/rings" element={<Rings />} />
    </Routes>
  </Router>
  );
}

export default App;
