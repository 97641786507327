import React from "react";
import { Nav, Navbar, InputGroup, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
function TopNavbar({ activeScreen }) {
  const cssForNavigation = {
    backgroundColor: "#104a5e",
    // backgroundSize: "cover",
    // backgroundPosition: "center",
  };

  return (
    <div>
      <div
        className="d-flex"
        style={{ height: 45, backgroundColor: "#d8c9b9" }}
      ></div>
      <div
        className="d-lg-flex justify-content-lg-between"
        style={{
          background: "#104a5e",
        }}
      >
        <div className="d-flex flex-column justify-content-center align-items-center p-3 col-lg-4 col-12">
          <img
            alt="logo"
            src="./logo1.png"
            className="img-fluid"
            width={"100"}
          />
          <h2
            style={{
              fontfamily: "Old Standard TT",
              background: "#cf9c41",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              fontSize: 40,
            }}
          >
            Gayatri Jewellers
          </h2>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center col-12 col-lg-8">
          <div className="d-flex align-items-center border border-1 rounded-5 py-1 px-3 w-75">
            <i className="bi bi-search text-white" />
            <div className="w-100">
              <input
                className="px-2 w-100"
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  outline: "none",
                  color: "white",
                }}
              />
            </div>
            <i className="bi bi-mic text-white" />
          </div>
          <div className="d-flex justify-content-around w-75">
            <Link className="btn text-white px-2 fs-4" to="/">Gold</Link>
            <button className="btn text-white px-2 fs-4">Silver</button>
            <button className="btn text-white px-2 fs-4">Bullions</button>
            <button className="btn text-white px-2 fs-4">Stones</button>
          </div>
        </div>
      </div>
      <Navbar style={{backgroundColor:"#104a5e"}} >
        <div className="d-flex justify-content-center w-100">
          <Nav>
            {/* <Nav.Link
              style={{ color: activeScreen === "Home" ? "gold" : "white" }}
              href="/"
            >
              Home
            </Nav.Link> */}
            <Nav.Link
              style={{ color: activeScreen === "Necklaces" ? "gold" : "white" }}
              href="/necklaces"
            >
              Mangalsutra
            </Nav.Link>
            <Nav.Link
              style={{ color: activeScreen === "Bangels" ? "gold" : "white" }}
              href="/bangels"
            >
              Bangels
            </Nav.Link>
            <Nav.Link
              style={{ color: activeScreen === "Rings" ? "gold" : "white" }}
              href="/rings"
            >
              Rings
            </Nav.Link>
          </Nav>
        </div>
      </Navbar>
      <a
        href="https://wa.me/+918888864635?text=I%20am%20interested%20in%20your%20product%20plese%20let%20me%20know%20how%20can%20I%20buy"
        className="float-whatsApp"
        target="_blank"
        rel="noreferrer"
      >
        <i className="bi bi-whatsapp" />
      </a>
    </div>
  );
}

export default TopNavbar;
